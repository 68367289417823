<template>
  <div
    class="
      login-regiter-card
      m-auto
      p-8
      ant-card ant-card-bordered
      shadow
      rounded-md
    "
  >
    <div class="mb-6 text-center">
      <AppLogo />
    </div>

    <h1 class="text-center text-xl mb-6">新しいアカウントを登録する</h1>
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
      layout="vertical"
    >
      <a-form-item label="名前" name="displayName">
        <a-input v-model:value="form.displayName" autocomplete="displayName">
          <template v-slot:prefix>
            <UserOutlined />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item label="メール" name="email">
        <a-input v-model:value="form.email" autocomplete="email">
          <template v-slot:prefix>
            <MailOutlined />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item label="パスワード" name="password">
        <a-input
          v-model:value="form.password"
          type="password"
          autocomplete="current-password"
        >
          <template v-slot:prefix>
            <LockOutlined />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item label="パスワード確認" name="confirm_password">
        <a-input v-model:value="form.confirm_password" type="password">
          <template v-slot:prefix>
            <LockOutlined />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item class="mb-4">
        <a-button block type="primary" html-type="submit" @click="handleSubmit">
          登録
        </a-button>
      </a-form-item>

      <a-form-item>
        <ButtonLoginGoogle />
      </a-form-item>
      <a-form-item class="m-0 text-center">
        <a @click="onGotoPrivacy()">
          規約をお読みいただき、同意の上でご登録をお願いします。
        </a>
      </a-form-item>
    </a-form>
  </div>
  <div class="text-center mt-3">
    すでにアカウトをお持ちですか？
    <a @click="onGotoLogin()"> ログイン </a>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Modal, message } from 'ant-design-vue'
import { maska } from 'maska'
import { REGISTER } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import ButtonLoginGoogle from '@/components/account/ButtonLoginGoogle.vue'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons-vue'
import AppLogo from '@/components/common/AppLogo.vue'

@Options({
  components: {
    AppLogo,
    UserOutlined,
    LockOutlined,
    MailOutlined,
    ButtonLoginGoogle,
  },
  directives: { maska },
})
export default class RegisterForm extends mixins(LoginRegisterMixin) {
  form = {
    displayName: '',
    email: '',
    password: '',
  }

  rules = {
    displayName: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    confirm_password: [
      {
        message: 'パスワードが間違ったようです。',
        trigger: 'blur',
        validator: (rule: unknown, value: string) => {
          return new Promise((resolve, reject) => {
            if (value !== this.form.password) {
              reject(new Error())
            }

            resolve(true)
          })
        },
      },
    ],
  }

  isActive = false

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  async handleSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doRegister()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doRegister() {
    const { email, displayName, password } = this.form
    const { dispatch } = this.$store

    await dispatch(REGISTER, {
      email,
      displayName,
      password,
    })
      .then(() => {
        message.success('アカウント登録が通常に成功されました。')
        this.onGotoLogin()
      })
      .catch((error: string) => {
        errorHandler([error])
      })
  }

  openCommingSoon() {
    Modal.confirm({
      title: '',
      content: 'Comming soon...',
      okText: '了解',
      cancelText: 'キャンセル',
    })
  }

  onGotoLogin() {
    this.$router.push({ name: 'login' })
  }

  onGotoPrivacy(): void {
    this.openCommingSoon()
  }
}
</script>
