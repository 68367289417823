
import { Options, mixins } from 'vue-class-component'
import { Modal, message } from 'ant-design-vue'
import { maska } from 'maska'
import { REGISTER } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import ButtonLoginGoogle from '@/components/account/ButtonLoginGoogle.vue'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons-vue'
import AppLogo from '@/components/common/AppLogo.vue'

@Options({
  components: {
    AppLogo,
    UserOutlined,
    LockOutlined,
    MailOutlined,
    ButtonLoginGoogle,
  },
  directives: { maska },
})
export default class RegisterForm extends mixins(LoginRegisterMixin) {
  form = {
    displayName: '',
    email: '',
    password: '',
  }

  rules = {
    displayName: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    confirm_password: [
      {
        message: 'パスワードが間違ったようです。',
        trigger: 'blur',
        validator: (rule: unknown, value: string) => {
          return new Promise((resolve, reject) => {
            if (value !== this.form.password) {
              reject(new Error())
            }

            resolve(true)
          })
        },
      },
    ],
  }

  isActive = false

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  async handleSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doRegister()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doRegister() {
    const { email, displayName, password } = this.form
    const { dispatch } = this.$store

    await dispatch(REGISTER, {
      email,
      displayName,
      password,
    })
      .then(() => {
        message.success('アカウント登録が通常に成功されました。')
        this.onGotoLogin()
      })
      .catch((error: string) => {
        errorHandler([error])
      })
  }

  openCommingSoon() {
    Modal.confirm({
      title: '',
      content: 'Comming soon...',
      okText: '了解',
      cancelText: 'キャンセル',
    })
  }

  onGotoLogin() {
    this.$router.push({ name: 'login' })
  }

  onGotoPrivacy(): void {
    this.openCommingSoon()
  }
}
